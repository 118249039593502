/* eslint
    func-names: "off"
*/
(function ($) {
  const { selectOneTimeDonationRadioButton, getAnnualDonationRadioButton, getMonthlyDonationRadioButton, selectAnnualDonationRadioButton, ONE_TIME_FREQUENCY, MONTHLY_FREQUENCY, ANNUAL_FREQUENCY, DONATION_TYPE_QUERY } = require('./utils/donation-form-helper.js')

   /*
    * Code for the Annual sustainer flow
    *
    * Out of the box, Springboard doesn't allow us to "annualize" a one-time donation
    * via a checkbox in the UI. In order to make a donation repeat annually, Springboard
    * requires that the "annual" radio button -- which we've hidden -- be selected. So in
    * order to get our UI to look the way we want, we need to update the radio button upon submit.
    * If the annual checkbox is checked, we want to manually select the SB annual radio button
    * to change the donation from one-time to annual on the SB submission
    *
    * NOTE: this file must be loaded after  aclu-multistep.js which moves the processing fee checkbox
    * the processing fee checkbox affects the placement of the annual checkbox.
    */
    function insertRenewalCheckbox(annual_checkbox_html) {
        $('.form-item-submitted-payment-information-payment-fields-credit-card-cvv').after(annual_checkbox_html)
        const frequency = $('#edit-submitted-donation-recurs-monthly input[type="radio"]:checked').val()
        if (frequency === ANNUAL_FREQUENCY) {
            // Reset OneTime Renewal Configuration after a form submission error in case
            // user goes back to step one we want them to see the one-time radio button selected
            // since the annual radio button is always hidden but gets checked on form submit
            selectOneTimeDonationRadioButton()
            const annualRenewalCheckbox = document.getElementById('edit-submitted-donation-annual-opt-in-1')
            annualRenewalCheckbox.checked = true
        }
        toggleRenewalCheckboxVisibility()
    }
    function toggleRenewalCheckboxVisibility() {
        //
        // We don't want a monthly donor to downgrade to an annual donation
        // so we hide the renewal checkbox unless the donor is making a one-time donation.
        //
        const renewalCheckbox = $('#webform-component-donation--annual-opt-in')
        const frequency = $('#edit-submitted-donation-recurs-monthly input[type="radio"]:checked').val()
        const oneTime = ONE_TIME_FREQUENCY
        if (renewalCheckbox.length) {
            // when one-time payment is selected give the user an option to renew annually
            // by showing the annual renewal checkbox
            if (frequency === oneTime) {
                renewalCheckbox.show()
            } else {
                // For other payment frequencies do not prompt the user to auto renew
                // hide the annual renewal checkbox
                renewalCheckbox.hide()
            }
        }
    }
    function togglePayOther(toggle) {
        const applepayButton = $('.form-actions input#edit-submit.btn.apple-pay-button')
        const paypalButton = $("#webform-component-extra-fields--payment-options input[type='radio'][value='1']").parent()
        const payOtherMessage = $('#webform-component-extra-fields--payment-message')

        // annual sustainers can only renew by credit card
        // hide applepay and paypal options if available.
        if (toggle === 'hide') {
            if (paypalButton.length) {
                paypalButton.hide()
            }
            if (applepayButton.length) {
                applepayButton.hide()
            }
            payOtherMessage.show()
        } else {
            if (paypalButton.length) {
                paypalButton.show()
            }
            if (applepayButton.length) {
                applepayButton.show()
            }
            payOtherMessage.hide()
        }
    }
    function setupAnnualAutoRenewOptIn() {
        const disclaimer = '<strong>Auto-renew my donation annually.</strong> I agree that ACLU, Inc. may charge this credit card on or after today’s date, and then again on a recurring basis each year, until I notify ACLU, Inc. I’d like to cancel <a target="_blank" href="https://www.aclu.org/donor-relations">here</a>.'
        const annual_renew_checkbox_html =
            '<div class="form-item webform-component webform-component-checkboxes control-group" id="webform-component-donation--annual-opt-in">' +
            '<div id="edit-submitted-donation-annual-opt-in" class="annual-checkbox">' +
            '<div class="form-item form-type-checkbox form-item-submitted-donation-annual-opt-in-1 control-group">' +
            '<input type="checkbox" id="edit-submitted-donation-annual-opt-in-1" name="submitted[donation][annual_opt_in][1]" value="1" class="form-checkbox">' +
            '<label class="option" for="edit-submitted-donation-annual-opt-in-1">' + disclaimer +
            '</label>' +
            '</div></div></div>'

        insertRenewalCheckbox(annual_renew_checkbox_html)

        document.body.addEventListener('change', function (event) {
            // If user changes payment to monthly hide annual renewal checkbox
            // and show applicable alternate payment methods availble for monthly donations
            const monthly = MONTHLY_FREQUENCY
            const oneTime = ONE_TIME_FREQUENCY
            const annualOptInCheckbox = 'edit-submitted-donation-annual-opt-in-1'
            const hasBankPayment = $('.webform-client-form .pay-with-bank').length

            if ( event.target.type === 'radio' && event.target.name === 'submitted[donation][recurs_monthly]') {
                toggleRenewalCheckboxVisibility()
                if (event.target.value === monthly) {
                    // monthly gifts can only be made via credit card or bank
                    if (hasBankPayment) {
                        $('.webform-client-form .pay-with-bank').show()
                    }
                } else if (event.target.value === oneTime) {
                    if ( $('#edit-submitted-donation-annual-opt-in-1').is(':checked') ) {
                        togglePayOther('hide')
                    }
                }
            } else if ( event.target.id === annualOptInCheckbox ) {
                // when checking or unchecking annual opt-in we know one-time is selected
                // toggle payment options in step 1 in case the user goes back
                if (event.target.checked) {
                    togglePayOther('hide')
                } else {
                    togglePayOther('show')
                }
            }
        })
        document.querySelector('form.webform-client-form').addEventListener('submit', function (event) {
                // If the annual checkbox is checked, select the SB annual radio button
                // to change the donation from one-time to annual on the SB submission
                const frequency = $('#edit-submitted-donation-recurs-monthly input[type="radio"]:checked').val()
                if ( $('#edit-submitted-donation-annual-opt-in-1').is(':checked') && frequency === ONE_TIME_FREQUENCY ) {
                    const donationAmount = FundraiserTotal.getCurrentAmount(Drupal.settings)
                    // select annual donation frequency
                    selectAnnualDonationRadioButton()
                    // select other radio amount and fill in field with selected  one-time donation amount
                    // so submit as annual donation
                    $('#edit-frequencies-sb-fs-annually-amount-other').click()
                    const recurringOtherAmountTextField = $('#edit-submitted-donation-recurring-other-amount')
                    recurringOtherAmountTextField.val(donationAmount)

                    // This next line is a bit strange, but is needed in order for processing fees to be added to
                    // annual donations when the processing fees checkbox is checked. In the code above, when we
                    // click the 'other' donation amount button with JS, it triggers a recalculation of processing fees.
                    // But at that time we have not yet set the donation amount, so the amount is assumed to be 0 when
                    // recalulating processing fees. Anything multiplied by 0 is 0, so we end up with 0 as the processing
                    // fee amount. In the line below, we trigger a keyup event on the recurring other amount text field.
                    // This triggers a recalculation of the processing fees amount via Springboard out-of-the-box code.
                    // And since by now we have set the donation amount, the correct processing fee is found.
                    recurringOtherAmountTextField.trigger('keyup');
                }
            })
    }
    window.addEventListener('DOMContentLoaded', function () {
        // When annual frequency is enabled it's inserted as a new radio button that we hide on step 1
        const annualFrequencyEnabled = getAnnualDonationRadioButton().length
        const monthlyFrequencyEnabled = getMonthlyDonationRadioButton().length

        if (annualFrequencyEnabled) {
            if (!monthlyFrequencyEnabled) {
                // if only one-time and annual are active (no monthly option),
                // hide the donation frequency buttons that display on step 1
                document.querySelector(DONATION_TYPE_QUERY).style.display='none'
            }
            setupAnnualAutoRenewOptIn()
             // Detecting if default aclu-donation.js form styling is completed before toggling other payment optoons
            const mutationTarget = document.querySelector('.default-form-wrapper #content form')
            const mutationObserverConfig = { attributes: true }
            const callback = function ( mutations ) {
                for( let mutation of mutations ) {
                    if ( mutation.attributeName === 'class' ) {
                        if ( mutation.target.classList.contains('styled-processed') ) {
                            // Specifically for after submit if  there is an error wait for the form to be styled before
                            // hiding the other payment options if auto renew my one-time donation has been selected.
                            if ( $('#edit-submitted-donation-annual-opt-in-1').length && $('#edit-submitted-donation-annual-opt-in-1').is(':checked') ) {
                                togglePayOther('hide')
                            }
                        }
                    }
                }
            }
            const observer = new MutationObserver ( callback )
            observer.observe ( mutationTarget, mutationObserverConfig )
        }
    })
})(jQuery);
